export function dateToDateObject(date: string | Date | undefined | null) {
  if (!date) {
    return new Date();
  }

  if (date instanceof Date) {
    return date;
  }

  return new Date(date);
}

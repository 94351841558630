import { formatDistanceToNow } from 'date-fns';
import * as dateFnsLocales from 'date-fns/locale';

import { dateToDateObject } from './dateToDateObject';
import { LocaleType } from '@/i18n';

/**
 * @description Class to format date
 * @example new Format('date-string').in('en').toRelativeTime()
 * @example new Format(dateObject).in('cs').toRelativeTime()
 * @example new Format().toIsoString()
 */
export class Format {
  private date: Date;
  private locale: LocaleType = 'en';

  constructor(date: Date) {
    this.date = dateToDateObject(date);
  }

  in(locale: LocaleType) {
    this.locale = locale;
    return this;
  }

  toRelativeTime() {
    const relativeTimeInLocale = formatDistanceToNow(this.date, {
      addSuffix: true,
      locale: dateFnsLocales[this.locale],
    });

    return relativeTimeInLocale;
  }

  toIsoString() {
    return this.date.toISOString();
  }

  toString() {
    return this.date.toLocaleString(this.locale);
  }
}

'use client';

import { LocaleType } from '@/i18n';

/**
 * @todo refactor
 */
const messages = {
  en: {
    one: '{count} reply',
    other: '{count} replies',
  },
  cs: {
    one: '{count} odpověď',
    few: '{count} odpovědi',
    many: '{count} odpovědí',
    other: '{count} odpovědí',
  },
  pl: {
    one: '{count} odpowiedź',
    few: '{count} odpowiedzi',
    many: '{count} odpowiedzi',
  },
  de: {
    one: '{count} Antwort',
    other: '{count} Antworten',
  },
  fr: {
    one: '{count} réponse',
    other: '{count} réponses',
  },
  es: {
    one: '{count} respuesta',
    other: '{count} respuestas',
  },
  it: {
    one: '{count} risposta',
    other: '{count} risposte',
  },
  ja: {
    other: '{count} 返信',
  },
};

function getPluralMessage(locale, count) {
  const pluralRule = new Intl.PluralRules(locale).select(count);
  const translation = messages?.[locale]?.[pluralRule];

  if (!translation) {
    console.error(
      `No translation for locale: ${locale} and count: ${count}. Rule: ${pluralRule}`
    );
    return '';
  }

  return translation.replace('{count}', count);
}

interface ResponsesProps {
  count: number;
  locale: LocaleType;
}

export default function Responses({ count, locale }: ResponsesProps) {
  const replyText = getPluralMessage(locale, count);
  return <>{replyText}</>;
}

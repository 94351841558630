import React from 'react';

const useLazyEffect = (
  effect: React.EffectCallback,
  dependencies: React.DependencyList
) => {
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    if (isMounted) {
      return effect();
    }
    setIsMounted(true);
  }, dependencies);
};

export default useLazyEffect;

'use client';

import React from 'react';

import { Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import styled, { css } from 'styled-components';

import BottomSlot from './BottomSlot';
import { MAP_LOCALE_TO_ID } from './constants';
import ItemsList from './ItemList';
import LanguageList from './LanguageList';
import useLazyEffect from '@/hooks/useLazyEffect';
import request from '@/utils/queryData/rest/clientRequest';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 940px;
  width: 100%;
`;

const Header = styled.div<{ $headerStyle: boolean }>`
  padding-bottom: 20px;

  ${({ $headerStyle }) =>
    $headerStyle &&
    css`
      padding: 5rem 0 1.88rem 0;
      text-align: center;
    `}
`;

const APPLICABLE_LOCALES = Object.keys(MAP_LOCALE_TO_ID);

export default function ForumWidget({
  locale: pageLocale,
  serverData,
  headerStyle = true,
}) {
  const [forumItems, setForumItems] = React.useState(serverData?.data);
  const [forumLocale, setForumLocale] = React.useState(pageLocale);
  const [isLoading, setIsLoading] = React.useState(false);
  const translate = useTranslations();

  const forumId = React.useMemo(
    () => MAP_LOCALE_TO_ID[forumLocale],
    [forumLocale]
  );

  const loadForumItems = React.useCallback(() => {
    setIsLoading(true);

    new request('forumCategoryById')
      .get({
        forumid: forumId,
      })
      .then(data => {
        setForumItems(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
      });
  }, [forumId]);

  useLazyEffect(() => {
    loadForumItems();
  }, [forumId]);

  return (
    <Wrapper>
      <Header $headerStyle={headerStyle}>
        <Typography variant="h3">
          {translate('homepage-forum-heading')}
        </Typography>
      </Header>
      <LanguageList
        locales={APPLICABLE_LOCALES}
        forumLocale={forumLocale}
        setForumLocale={setForumLocale}
      />
      <ItemsList
        items={forumItems}
        isLoading={isLoading}
        forumLocale={forumLocale}
      />
      <BottomSlot />
    </Wrapper>
  );
}

import React from 'react';

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Link from 'next/link';

import {
  Wrapper,
  Item,
  Title,
  Detail,
  UserName,
  MetaInfoList,
} from './ItemList.styled';
import Responses from './Responses';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import RelativeDate from '@/ui/components/RelativeDate';

const NoItems = () => <div>No items found</div>;

function AvatarSizeObject(size) {
  this.width = size;
  this.height = size;
}

/**
 * @todo link to the forum
 * @todo colors - theme
 * @todo button mui
 */
export default function ItemsList({
  items: forumItems,
  isLoading,
  forumLocale,
}) {
  const breakpoint = useBreakpoint();

  // size in px
  const avatarSize = React.useMemo(() => {
    if (breakpoint === 'xs') return new AvatarSizeObject(32);
    if (breakpoint === 'sm') return new AvatarSizeObject(32);
    return new AvatarSizeObject(40);
  }, [breakpoint]);

  if (!Array.isArray(forumItems)) return null;

  // throw new Error(`Items must be an array but got ${typeof forumItems}`);

  if (forumItems?.length === 0) return <NoItems />;

  return (
    <Wrapper $isLoading={isLoading}>
      {forumItems.map(({ data: item }) => (
        <Link key={item.topicid} href={item.url}>
          <Item>
            <Avatar src={item.avatar} sx={avatarSize} />
            <Stack>
              <Title>{item.title}</Title>
              <Detail>
                <UserName>{item.nicename}</UserName>
                <MetaInfoList>
                  <li>
                    <RelativeDate
                      date={item.lastupdate}
                      dateOnly={true}
                      locale={forumLocale}
                    />
                  </li>
                  <li>
                    <Responses count={item.postcount} locale={forumLocale} />
                  </li>
                </MetaInfoList>
              </Detail>
            </Stack>
          </Item>
        </Link>
      ))}
    </Wrapper>
  );
}

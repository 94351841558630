import React from 'react';

import { useTranslations } from 'next-intl';
import styled from 'styled-components';

import Button from '@/ui/components/Button';

const Wrapper = styled.div`
  padding-top: 3.15rem;
  display: flex;
  justify-content: center;
`;

/**
 * @todo address url
 */
export default function BottomSlot() {
  const translate = useTranslations();

  return (
    <Wrapper>
      <a href="https://forum.prusa3d.com/" target="_blank" rel="noreferrer">
        <Button label={translate('homepage-forum-button')} />
      </a>
    </Wrapper>
  );
}

import React from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { LOCALE_TO_LANGUAGE } from '@/i18n';

/**
 * @todo cesky misto cestina
 */
export default function LanguageList({ locales, forumLocale, setForumLocale }) {
  const handleChange = (event, newValue) => {
    setForumLocale(newValue);
  };

  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'auto',
      }}
    >
      <Tabs
        value={forumLocale}
        onChange={handleChange}
        centered
        sx={{
          minWidth: '720px',
        }}
      >
        {locales.map(language => (
          <Tab
            key={language}
            value={language}
            label={LOCALE_TO_LANGUAGE[language]}
            tabIndex={0}
            sx={{
              color: 'black',
              '&:not(.Mui-selected):hover': {
                textDecoration: 'underline',
              },
              '&.Mui-selected': {
                fontWeight: 'bold', // Bold text for active tab
                borderSize: '3px', // 3px border for active tab
                color: 'black', // Ensure active tab stays black
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}

import React from 'react';

import styled from 'styled-components';

import { Format } from '@/lib/dateTime/formatDate';
import { log } from '@/utils/logger';

const Time = styled.time``;

/**
 * @todo translate, refactor - unify with second component
 */
export default function RelativeDate({
  date: dateString,
  dateOnly = false,
  locale,
}) {
  const [relativeDate, dateIsoString] = React.useMemo(() => {
    const date = new Format(dateString);
    const relativeDate = date.in(locale).toRelativeTime();
    const dateIsoString = date.toIsoString();
    const timeWithouAbout = relativeDate.replace('about ', ''); // @todo refactor
    return [timeWithouAbout, dateIsoString];
  }, [dateString]);

  if (!dateString) {
    log.error('RelativeDate: date is missing');
    return null;
  }

  if (dateOnly) {
    return <Time dateTime={dateIsoString}>{relativeDate}</Time>;
  }

  return <Time dateTime={dateIsoString}>Last updated {relativeDate}</Time>;
}

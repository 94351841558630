import styled from 'styled-components';

export const Wrapper = styled.div<{ $isLoading: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ $isLoading }) => $isLoading && 'opacity: 0.5;'}
  border-bottom: 1px solid var(--grey-200-border, #e0e0e0);
`;

export const Item = styled.div`
  display: flex;
  gap: 1.25rem;
  border-top: 1px solid var(--grey-200-border, #e0e0e0);
  padding: 1.25rem 1.25rem 1.25rem 0.625rem;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: black;
`;

export const Detail = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const UserName = styled.div`
  color: var(--Black, #2a2a2a);
  /* font-family: 'Atlas Grotesk'; */
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: bold;
  line-height: 1.25rem; /* 153.846% */
`;

export const MetaInfoList = styled.ul`
  display: flex;
  gap: 1rem;
  color: #959595;
  /* font-family: 'Atlas Grotesk'; */
  font-size: 0.6875rem;
  font-style: normal;
  /* font-weight: 400; */
  line-height: 1.25rem; /* 181.818% */
  padding: 0;

  li {
    padding: 0;
  }
`;
